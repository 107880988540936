export const grupaCenowa2 = [
'Aktorstwo',
'Architektura Krajobrazu',
'Grafika Komoputerowa',
'Ceramika',
'Analityka gospodarcza',
'Rachunkowość',
'Analiza i zarządzanie w biznesie',
'Inżynieria produkcji',
'Indologia',
'Koreanistyka',
'Judaistyka',
'Logistyka',
'Zarządzanie lotnictwem',
'technologie informatyczne w logistyce',
'Audiofonologia',
'Elektroradiologia',
'Farmacja',
'Inżynieria BHP',
'Prawo',
'Akwakultura i bezpieczeństwo żywności', 
'Biologia',
'Biotechnologia',
'Eksploracja mórz i ocenów',
'Geografia',
'Geologia',
'Leśnictwo',
'Mikrobiologia',
'Oceanografia',
'Ogrodnictwo',
'rolnictwo',
'Rybacwo',
'Technologia rolnicza i leśna',
'Weterynaria',
'Informatyka',
'Informatyka stosowana',
'Techniczne zastosowania internetu',
'Teleinformatyka',
'Akustyka',
'Architektura',
'Architektira i urbanistyka',
'Gospodarka przestrzenna',
'Inżynieria internetu',
'Inżynieria środowiska',
'Bioetyka',
'Interdyscyplinarne studia strategiczne',
'Kognitywistyka',
'Kognitywistyka i komunikacja',
'Kognitywistyka komunikacji',
'Bezpieczeństwo produktów zwierzęcych',
'Inspekcja weterynaryjna',
'Psychologia i bilogia zwierząt',
'Zoofizjoterapia',
'Zootechnika',
'Analityka kryminalistyczna i sądowa',
'Biologia i zdrowie człowieka',
'Agroinżynieria',
'Automatyzacja i robotyzacja procesów produkcji',
'Ekoenergetyka',
'Ekologiczne źródła energi',
'Eksploatacja infrastruktury komunikacyjnej',
'Elektrotechnika',
'Energetyka cieplna',
'Energetyka i inżynieria środowiska',
'Energetyka odnawialna i zarządzanie energią',
'Infrastruktura komunikacyjna i transport multimodalny',
'Innowacyjne technologie i nowowczesne materiały',
'Inteligentne technologie',
'Inteligentny przemysł',
'Inżynieria cyklu życia produktu',
'Inżynieria czystego powietrza',
'Zarządzanie procesami przemysłowymi',
'Inżynieria kształtowania środowiska',
'Inżynieria logistyki',
'Inżynieria materiałowa',
'Inżynieria meblarstwa',
'Inżynieria przemysłu spożywczego',
'Inżynieria rolono-spożywczego',
'Nauki o jakości',
'Papiernictwo i poligrafia',
'Prawo medyczne',
'Analityka biznesu',
'Ekonomiczna analiza prawa',
'Ekonomiczne studia analityczne',
'Finanse i rachunkowość ACCA',
'Inżynieria i zarządzanie procesami przemysłowymi',
'Cyfryzacja i zarządzanie danymi w biznesie',
'Technologia żywności i żywienia człowieka',
'Prawo europejskie',
'Prawo finansowe i skarbowość',
'Prawo i podatki w biznesie',
'Techniki kryminalistyczne',
'Inżynieria wzornictwa przemysłowego',
'Projektowanie wnętrz',
'E-gospodarka przestrzenna',
'Geografia fizyczna z geoinformacją',
'Geografia stosowana',
'Geologia poszukiwawcza'

]