export const grupaCenowa1 = [
'Architektura wnętrz',
'Edukacja Artystyczna',
'Filmoznstwo',
'Muzykologia',
'Wzornictwo',
'Finanse i inwestycje',
'Towaroznastwo',
'Archiwistyka',
'Ekonomia',
'Gospodraka i zarządzanie oublicznie',
'Marketing',
'Przedsiebiorczość',
'Zarządzanie',
'Zarządzanie informacją',
'Zarządzanie publiczne',
'Zarządzanie środowiskiem',
'Zarządzanie wiedzą',
'Filologia polska',
'Studia azjatyckie',
'Agroturystyka',
'Gastronomia i hotelarstwo',
'Gospodraka turystyczna',
'Sport',
'Turystyka i rekreacja',
'Wychowanie fizyczne',
'Antropologia',
'Archeologia',
'Coaching',
'Dzinnikarstwo i kounikacja społeczne',
'Edytorstwo',
'Etnologia',
'Filozofia',
'Historia',
'Historia sztuki',
'Bibliotekoznastwo',
'Kulturoznastwo',
'Media i komunikacja społeczna',
'Międzynarodowe stosunki gospodarcze',
'Muzeologia',
'Okcydentalistyka',
'Wiedza o teatrze',
'Wschodoznastwo',
'Transport',
'Dietetyka',
'Fizjoterapia',
'Higiena dentystyczna',
'Kosmetologia',
'Pielęgniarstwo',
'Położnictwo',
'Techniki dentystyczne',
'Terapia zajęciowa',
'Zdrowie publiczne',
'BHP',
'Obronność',
'Bezpieczeństwo narodowe',
'Bezpieczeństwo wewnętrzne',
'Inżynieria bezpieczeństwa',
'Wojskoznastwo',
'Amerykanistyka',
'Etyka',
'Kogniwistyka',
'Nauki o rodzinie',
'Politologia',
'Polityka Publiczna',
'Polityka społeczna',
'Projektowanie aktywności społecznej',
'Psychologia',
'Socjologia stosowana',
'Socjologia',
'Socjologia techniki i ekologi',
'Zdrowie Środowiskowe',
'Androgogika',
'Animacja kultury',
'Logopedia',
'Pedagogika',
'Administracja',
'Kryminologia',
'Bezpieczeństwo żywności',
'Farmerstwo',
'Geoinformaja',
'Gospodarka odpadami',
'Medycyna roślin',
'Ochrona roslin i fitosanitarna',
'Ochrona środowiska',
'Onawialne źródła energii',
'Psychologia zwierząt',
'Zootechnika',
'Dyplomacja Europejska',
'Dyplomoacja i stosunki konsularne',
'Europeistyka',
'Stosunki międzynarodowe',
'Studia Bałtyckie',
'Edukacja techniczna i informatyczna',
'Religioznastwo',
'Teologia',
'Analiza i kreowanie trendów',
'Antropologia literatury, teatru i filmu',
'Arcitektura informacji',
'Arteterapia',
'Dzienikarstwo i komunikacja społeczna',
'Dzienniekarstwo i nowe media',
'Biznesowe wykorzystanie dzidzictwa kulturowego',
'Coaching filozoficzny',
'Cyberdemokracja i studia nad rozwojem',
'Człowiek w cyberprzetrzeni',
'Design i komunikacja społeczna',
'Doradztwo filozoficzne i coaching',
'Doradztwo kariery i doradzto zawodowe',
'Edukacja Kulturalna',
'Edytorstwo klasyczne i cyfrowe',
'Etnologia i antropologia kulturowa',
'Integracja Europejska',
'Europejski rynek pracu',
'Filozofia i etyka',
'Filozofia i psychologia rozwoju osobistego',
'Humanistyka cyfrowa',
'Informacja naukowa i bibliotekoznastwo',
'Informacja w środowisku cyfrowym',
'Interdyscyplinarne studia Europejskie',
'Język i społeczeństwo',
'Jęyk polski w komunikacji społecznej',
'Komunikacja cyfrowa',
'Komunikacja europejska',
'Komunikacja międzykulturowa',
'Komunikacja promocyjna i kryzysowa',
'Komunikacja wizerunkowa',
'Krytyka artystyczna',
'krytyka literacka',
'Kultura i sztuka współczesna',
'Logopedia ogółna i kliniczna',
'Logopedia z audiologią',
'Managment instytucji publicznych i public relations',
'Międzynarodowe studia kulturowe',
'Migrantologia',
'Nauki o polityce',
'Nowe media i kultura cyfrowa',
'Obronność Państwa',
'Ochrona dóbr kultury',
'Pedagogika przedszkolna i wczesnoszkolna',
'Pedagogika wczesnej edukacji',
'Performatyka',
'Praca socjalna',
'Produkcja medialna',
'Projektowanie kultury',
'Przewodnictwo i pilotaż turystyczny',
'Socjologia cyfrorwa',
'Socjologia interwencji społecznych',
'Stosunki i prawo międzynarodowe',
'Studia miejskie',
'Studia Europejskie',
'Turystyka kulturowa',
'Turystyka historyczna i kulturowa',
'Wojsko  w systemie służb publicznych',
'Judaistyka',
'Militarioznastwo',
'Historia wojskowości',
'Kynologia',
'Zwierzęta w rekreacji, edukacji i terapii',
'Bezpieczeństwo zdrowotne',
'Coaching medyczny',
'Dietetyka kliniczna',
'Dietetyka w sporcie i rekreacji',
'Elektroradiologia',
'Analityka Bezpieczeństwa',
'Architektura wnętrz',
'Bezpieczeństwo techniczne',
'Biogospodarka',
'Biogospodarka zrównoważona',
'Biznes elektroniczny',
'Browarnictwo i słodownictwo',
'Cyberbezpieczeństwo',
'Ekologia miasta',
'Gospodarka obiegu zamkniętego',
'Inteligentne miasta',
'Higiena stomatologiczna',
'Odnowa bilogiczna',
'Pielęgniarstwo pomostowe',
'Położnictwo pomostowe',
'Ratownictwo medyczne',
'Telemedycyna i projekty w ochronie zdrowia',
'Zarządzanie ryzykiem zdrowotnym',
'Zarządzanie w systemie ochrony zdrowia',
'Zdrowie publiczne i epidemiologia',
'Zdrowie środowiskowe',
'Zdrowie środowiskowe i BHP',
'Żywienie człowieka i dietetyka',
'Żywienie człowieka i dietoterapia',
'Żywienie człowieka i ocena żywności',
'Agrobiznes',
'Bankowość i finanse cyfrowe',
'Bankowość i zarządzanie ryzykiem',
'Bezpieczeństwo międzynarodowe i dyplomacja',
'Bezpieczeństwo w biznesie',
'Bezpieczeństwo w biznesie i administracji',
'Biznes i administracja',
'Biznes i technologia ekologiczna',
'Cyfryzacja i zarządzanie danymi w biznesie',
'Doradztwo inwestycyjno-gospodarcze',
'Doradztwo podatkowe',
'E-administracja',
'E-biznes',
'E-commerce',
'Ekonomia biznesu i finanse',
'Ekonomia menadżerska',
'Ekonomia społeczna',
'Ekonomika i finanse przedsiębiorstw',
'Finanse i biznes międzynarodowy',
'Finanse i controling',
'Finanse i ekonomia w biznesie',
'Finanse i rachunkowość',
'Finanse i rachunkowość w biznesie',
'Finanse menadżerskie',
'Finanse, audyt, inwestycje',
'Finanse, bankowość, ubezpieczenia',
'Finansowo-prawny',
'Globalny biznes, finanse i zarządzanie',
'Gospodraka cyfrowa',
'Gospodarka i administracja publiczna',
'Gospodarka i ekonomia w dziejach',
'Gospodarka miejska i nieruchomości',
'Gospodarka nieruchomościami',
'Gospodrka przestrzenna',
'Gospodarka turystyczna',
'Handel międzynarodowy',
'Innowacje w biznesie',
'Innowacyjność i zarządzanie sferą publiczną',
'Inwestycje i nieruchomości',
'Komunikacja i psychologia w biznesie',
'Managment',
'Konsulting prawny i gospodarczy',
'Nadzór i kontrola',
'Nowoczesny handel',
'Organizacja i zarządzanie',
'Planowanie i organizacja przestrzeni',
'Prawo i finanse',
'Prawo w administracji i gospodarcze',
'Przedsiębiorczość i finanse',
'Przedsiębiorczość i innowacje w gospodarcze',
'Przesiębiorczość i inwestycje',
'Przedsiębiorczość i zarządzanie innowacjami',
'Przemysł 4.0',
'Psychologia w biznesie',
'Rynek nieruchomości',
'Ubezpieczenia',
'Zarządzanie zasobami ludzkimi',
'Automatyzacja procesów biznesowych - BPA',
'Biznes Manager',
'Coaching stylu życia',
'Creative Mamagment',
'Design w biznesie',
'Digital media',
'Dowodzenie',
'Ekobiznes',
'Ekomiasto',
'Finanse i zarządzanie w przedsiębiorstwie',
'Geopolityka i studia strategiczne',
'HR',
'Marketing i logistyka',
'Marketing internetowy',
'Marketing wizerunkowy',
'Media społecznościowe w zarządzaniu',
'Mediaworking',
'Mikrobiznes',
'Obsługa biznesu',
'Organizowanie rynku pracy',
'Prawo w zarządzaniu',
'Sprzedaż i marketing',
'Zarządzanie logistyką i łańcuchami dostaw',
'Zarządzanie kulturą i mediami',
'Zarządzanie Państwem',
'Administracja europejska',
'Administracja i bezpieczeństwo wewnętrzne',
'Administracja i cyfryzacja',
'Administracja publiczna i komunikowanie społeczne',
'Administrowanie środowiskiem',
'Bezpieczeństwo i prawo',
'Mediacje i negocjacje',
'Kryminalistyka',
'Kryminalistyka i systemy bezpieczeństwa',
'Kryminalistyka z kryminologią',
'Menadżer administracji publicznej'
]