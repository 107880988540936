export const grupaCenowa3 = [
'Ekonometria',
'Jachting',
'Lotnictwo i kosmonautyka',
'Analityka medyczna',
'Biotechnologia medyczna',
'Prawo Kanoniczne',
'Analityka żywności i środowiska wodnego',
'Geomonitoring',
'Górnictwo i geologia',
'Inżynieria i gospodarka wodna',
'Ceramika',
'Budownictwo',
'Ekoenergetyka',
'Elektronika',
'Elektronika i telekomunikacja',
'Elektrotechnika',
'Energetyka',
'Inżynieria akustyczna',
'Inżynieria produkcji',
'Telekomunikacja',
'Groznastwo',
'Analityka weterynaryjna',
'Biologia medyczna',
'Architektura',
'Budowa i eksploracja infrasytuktury transportu szynowego',
'Budowa jachtów',
'Chłodnictwo i klimatyzacja',
'Elektroenergetyka',
'Elektromobilność',
'Elektromobilność i energia odnawialna',
'Elektronika',
'Elektronika i systemy komputerowe',
'Energetyka przemysłowa i odnawialna',
'Grafika',
'Inteligentna elektronika',
'Inżynieria danych',
'Inżynieria internetu rzeczy',
'Inżynieria mechaniczna i materiałowa',
'inżynieria środków transportu',
'Metalurgia',
'Optometria',
'Optyka okularowa',
'Audyt finansowy',
'Data Science',
'Metody ilościowe w ekonomii i systemy informacyjne',
'Budownictwo zrównoważone',
'Projektowanie mebli'

]