import React, { useEffect } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import algo from './algo.json'
import { Button } from '@mui/material';
import { grupaCenowa1 } from './grupaCenowa1';
import { grupaCenowa2 } from './grupaCenowa2';
import { grupaCenowa3 } from './grupaCenowa3';
import { grupaCenowa4 } from './grupaCenowa4';



function App() {
  const [cenyPodstawowe, setCenyPodstawowe] = React.useState('Wszytskie')
  const [grupyCenowe, setGrupyCenowe] = React.useState('Wszytskie')
  const [czas, setCzas] = React.useState('T1')
  const [strony, setStrony] = React.useState('S1')
  const [rodzajPracy, setRodzajPracy] = React.useState('PD')
  const [kombinacja, setKombinacja] = React.useState(`${cenyPodstawowe}+${czas}+${strony}`)
  const [rezultat, setRezultat] = React.useState({})
  const [zakres, setZakres] = React.useState("ZC")

 

  useEffect(() => {
    setKombinacja(`${cenyPodstawowe}+${czas}+${strony}`)
  }, [cenyPodstawowe, czas, strony, kombinacja]);

  const handleCenyPodstawowe = (event) => {
    let grupa = event.target.value
    if (grupa.includes("GR1")){
    setCenyPodstawowe(event.target.value)
    setGrupyCenowe("GRI")
  }else if (grupa.includes("GR2")){
    setCenyPodstawowe(event.target.value)
    setGrupyCenowe("GRII")

  }else if (grupa.includes("GR3")){
    setCenyPodstawowe(event.target.value)
    setGrupyCenowe("GRIII")

  }else if (grupa.includes("GR4")){
    setCenyPodstawowe(event.target.value)
    setGrupyCenowe("GRIV")

  } else {
    setCenyPodstawowe(event.target.value)
    setGrupyCenowe(event.target.value)
  }

  }
  const handleCzas = (event) => {
    setCzas(event.target.value)

  }
  const handleStrony = (event) => {
    setStrony(event.target.value)
  }

  const handleRodzajPracy = (event) => {
    setRodzajPracy(event.target.value)
  }

  const handleZakres = (event) => {
    setZakres(event.target.value)
  }
  
  const oblicz = () => {
    // let wynik = cennik.filter(s => s.includes(kombinacja));
    // console.log(wynik)
    // wynik = wynik[0].substring(10)
    // setRezultat(wynik)
    let wynik = algo.find(e => (e['Grupa Cenowa'] == grupyCenowe && e['RODZAJ PRACY']== rodzajPracy && e['ILOŚĆ STRON']==strony && e['TERMIN']==czas && e['ZAKRES ZLECENIA'] == zakres));
   setRezultat(wynik)
  }

 
 console.log(grupyCenowe)
 console.log(rodzajPracy)
 console.log(strony)
 console.log(czas)
 console.log(zakres)

 const wyniki = () => {
   if(rezultat === undefined){
     return <Typography variant="h5" component="h2">Brak danych</Typography>
   }else{
     return <Typography variant="h5" component="h2">{rezultat?.Cena} / {rezultat['STAWKA DLA REDAKTORA']}</Typography>
   }
 }

  return (
    <>
   
    <Box sx={{ mt: 5, width: '80%', display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
      <FormControl sx={{ width: 200 }} >
        <InputLabel id="cenyPodstawowe">Grupa cenowa</InputLabel>
        <Select
          labelId="cenyPodstawowe"
          id="cenyPodstawowe"
          value={cenyPodstawowe}
          label="Ceny podstawowe"
          onChange={handleCenyPodstawowe}
          

        >
          <MenuItem value={'Wszytskie'}>Wszystkie</MenuItem>
          {grupaCenowa1.map((item, index)=>{
            return <MenuItem key={Math.random()} value={'GR1'+index}>{item}</MenuItem>
          })}
            {grupaCenowa2.map((item, index)=>{
            return <MenuItem key={Math.random()} value={'GR2'+index}>{item}</MenuItem>
          })}
             {grupaCenowa3.map((item, index)=>{
            return <MenuItem key={Math.random()} value={'GR3'+index}>{item}</MenuItem>
          })}
             {grupaCenowa4.map((item, index)=>{
            return <MenuItem key={Math.random()} value={'GR4'+index}>{item}</MenuItem>
          })}
          
          
          
          
        


        </Select>
      </FormControl>


          




      <FormControl sx={{ width: 200 }} >
        <InputLabel id="rodzajpracy">Rodzaj pracy</InputLabel>
        <Select
          labelId="rodzajpracy"
          id="rodzajpracy"
          value={rodzajPracy}
          label="Rodzaj pracy"
          onChange={handleRodzajPracy}
        >
          <MenuItem value={'PD'}>Praca dyplomowa</MenuItem>
          <MenuItem value={'PZ'}>Praca zaliczeniowa</MenuItem>
        


        </Select>
      </FormControl>
      <FormControl sx={{ width: 200 }} >
        <InputLabel id="czas">Czas</InputLabel>
        <Select
          labelId="czas"
          id="czas"
          value={czas}
          label="Czas"
          onChange={handleCzas}
        >
          <MenuItem value={'T1'}>do 3 dni</MenuItem>
          <MenuItem value={'T2'}>4-7 dni</MenuItem>
          <MenuItem value={'T3'}>8-14 dni</MenuItem>
          <MenuItem value={'T4'}>15-30 dni</MenuItem>
          <MenuItem value={'T5'}>ponad 30 dni</MenuItem>


        </Select>
      </FormControl>

      <FormControl sx={{ width: 200 }} >
        <InputLabel id="czas">Ilość stron</InputLabel>
        <Select
          labelId="strony"
          id="strony"
          value={strony}
          label="Ilość stron"
          onChange={handleStrony}
        >
          <MenuItem value={'S1'}>do 5</MenuItem>
          <MenuItem value={'S2'}>6 do 10</MenuItem>
          <MenuItem value={'S3'}>11 do 20</MenuItem>
          <MenuItem value={'S4'}>21 do 30</MenuItem>
          <MenuItem value={'S5'}>31 do 40</MenuItem>
          <MenuItem value={'S6'}>41 do 50</MenuItem>
          <MenuItem value={'S7'}>pow 50</MenuItem>


        </Select>
      </FormControl>


      <FormControl sx={{ width: 200 }} >
        <InputLabel id="czas">Zakres zlecenia</InputLabel>
        <Select
          labelId="zakres"
          id="zakres"
          value={zakres}
          label="Zakres zlecenia"
          onChange={handleZakres}
        >
          <MenuItem value={'ZC'}>Całość</MenuItem>
          <MenuItem value={'CT'}>Część teoretyczna</MenuItem>
          <MenuItem value={'CP'}>Część praktyczna</MenuItem>
          <MenuItem value={'ZAL'}>Praca zaliczeniowa</MenuItem>
      


        </Select>
      </FormControl>
      <Button variant="contained" onClick={oblicz}>Oblicz</Button>
   
        {wyniki()}
      {/* {rezultat?.Cena ? rezultat?.Cena : "Brak danych"} Stawka dla redaktora:{(rezultat['STAWKA DLA REDAKTORA'] !== undefined) ? rezultat['STAWKA DLA REDAKTORA'] : "Brak danych"} */}
   


    </Box>
    </>

  );
}

export default App;
