export const grupaCenowa4 = [
'Matematyka stosowana',
'Nawigacja',
'Wirtotechnologia',
'Fizyka medyczna',
'Inżynieria Biomedyczna',
'Fizyka w bilogi i medycynie',
'Agrochemia',
'Astrofizyka i kosmologia',
'Astronomia',
'Biochemia',
'Chemia',
'Fizyka',
'Geofizyka',
'Bioinformatyka',
'Informatyka i ekonometria',
'Matematyka',
'Neuroinformatyka',
'Automatyka i robotyka',
'Chemia budowlana',
'Energetyka i chemia jądrowa',
'Fizyka techniczna',
'Geodezja i kartografia',
'Inżynieria chemiczna i procesowa',
'Inżynieria materiałów kompozytowych',
'Inżynieria materiałowa',
'Inżynieria mechaniczno-medyczna',
'Iinżynieria medyczna',
'Inżynieria naftowa i gazownictwa',
'Inżynieria nanostruktur',
'Mechanika i budowa maszyn',
'Mechatronika',
'Metakurgia',
'Nanotechnologia',
'Oceanotechnika',
'Optometria',
'Optyka',
'Lingwistyka stosowana',
'Biocybernetyka i inżynieria biomedyczna',
'Biofizyka',
'Biomedycyna',
'Biotechnologia medyczna',
'chemia i toksykologia sądowa',
'Fizyka Medyczna',
'Automatyka i eektronika praktyczna',
'Automatyka i informatyka przemysłowa',
'Automatyka i robotyka przemysłowa',
'Cybernetyka',
'Robotyka',
'Bioinżynieria',
'Elektromechatronika',
'Elektrotechnika i automatyka',
'Fotonika',
'Geodezja i planowanie przestrzenne',
'Geoinformatyka',
'Geoinżynieria i eksploatacja surowców',
'Geologia stosowana',
'Geotechnologie, hydrotechnika, transport wodny',
'Górnictwo i geologia',
'Hydrografia Morska',
'Informatyka techniczna',
'Inżynieria akustyczna',
'Inżynieria cyberprzestrzeni',
'Inżynieria chemiczna i biochemiczna',
'Inżynieria farmaceutyczna',
'Inżynieria geoprzestrzenna',
'Inżynieria górnicza',
'Inżynieria kwantowa',
'Inżynieria lotnicza',
'Inżynieria kosmiczna',
'Modelowanie komputerowe',
'Biostatystyka',
'Systemy diagnostyczne w medycynie',
'Matematyka i ekonomia',
'Matematyka w finansach',
'Geodezja i kataster'

]